import { TreeNode } from '@zencity/common-ui';
import { ClassificationTreeNodeData } from 'components/ClassificationSubTopicsFilter/ClassificationSubTopicsFilter.helpers';

export const findMatchingClassificationTopicOption = (
  classificationTopicsOptions: TreeNode[],
  subTopicValue: string,
): TreeNode | undefined => {
  const matchingClassificationTopicOption = classificationTopicsOptions.find((classificationTopicOption) => {
    const { data: classificationTopicData } = classificationTopicOption;
    const { classificationSubTopics } = classificationTopicData as ClassificationTreeNodeData;
    return classificationSubTopics.some((classificationSubTopic) => classificationSubTopic.value === subTopicValue);
  });

  return matchingClassificationTopicOption;
};

export const findMatchingClassificationSubTopicOption = (
  classificationTopicOption: TreeNode,
  subTopicValue: string,
): TreeNode | undefined => {
  if (!classificationTopicOption.children) return undefined;
  const matchingClassificationSubTopic = classificationTopicOption.children.find((classificationSubTopicOption) => {
    const { data: classificationSubTopicData } = classificationSubTopicOption;
    const { classificationSubTopics } = classificationSubTopicData as ClassificationTreeNodeData;
    return classificationSubTopics[0].value === subTopicValue;
  });
  return matchingClassificationSubTopic;
};
