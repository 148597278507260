/* eslint-disable complexity,max-statements,prefer-destructuring */
import { setBearerAuthToken } from '@zencity/microfrontend-utils';
import axios, { AxiosInstance } from 'axios';
import { logger } from 'utils/community-asks/logger';
import { config } from '../../config/config';
import { getClientId } from './client';

export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const isServedInsideCorePlatform = !!process.env.ASSET_PATH;
  const extraHeaders = {
    'X-Zc-Client-Id': getClientId(),
  } as Record<string, string>;
  // postman (for example) does not work with credentials
  // (such as cookies, authorization headers or TLS client certificates):
  let withCredentials: boolean = baseURL.includes('.zencity.io');

  if (config.environment === 'development' && !isServedInsideCorePlatform) {
    if (process.env.X_ZC_USER_ID) {
      extraHeaders['X-Zc-User-Id'] = process.env.X_ZC_USER_ID;
    }
    if (process.env.VAULT_BYPASS_HAS_API_ACCESS_SECRET) {
      extraHeaders['X-Api-Access-Token'] = process.env.VAULT_BYPASS_HAS_API_ACCESS_SECRET;
    }
    if (process.env.VAULT_USERNAME && process.env.VAULT_PASSWORD) {
      const userPassword = `${process.env.VAULT_USERNAME}:${process.env.VAULT_PASSWORD}`;
      extraHeaders['Authorization'] = `Basic ${btoa(userPassword)}`; // eslint-disable-line @typescript-eslint/dot-notation
    }

    if (!withCredentials) {
      withCredentials = baseURL.search(/(localhost)|(127\.0\.0\.1)/gi) > -1;
    }
  }

  const instance = axios.create({
    baseURL,
    withCredentials,
  });

  // add all custom headers to the request:
  instance.interceptors.request.use((request) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [header, value] of Object.entries(extraHeaders)) {
      request.headers[header] = value;
    }

    return request;
  });

  const auth0Token = sessionStorage.getItem('auth0Token');
  if (!auth0Token && config.environment !== 'testing' && config.environment !== 'development') {
    logger.error('Surveys - Auth0 Token is missing or invalid.');
  }
  instance.interceptors.request.use(setBearerAuthToken.bind(null, <string>auth0Token));

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // flatten axios' error to a normal Error.
      const message = error.message;
      try {
        let resData = error.response?.data;
        if (typeof resData === 'object' && resData !== null) {
          resData = JSON.stringify(resData);
        } else {
          resData = resData.toString();
        }
        return Promise.reject(new Error(`${message}; ${resData}`));
      } catch (secondError) {
        return Promise.reject(new Error(message));
      }
    },
  );

  return instance;
};
